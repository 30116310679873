/* WP********** */

.wp {
    position: absolute;
    top: 80%;
    right: 10px;

    position: fixed;

    text-align: center;
}

.wp h4 {
    font-size: 1rem;
    font-weight: 300;
    background-color: #23C860;
    color: white;
    padding: .5rem;
    border-radius: 30px;
    margin: 0;

}

.wp li {
    color: #23C860;
    font-size: 50px;


}







@media screen and (max-width: 2000) {}


@media screen and (max-width: 1150px) {}

@media screen and (max-width: 750px) {
    .wp li {
        color: #23C860;
        font-size: 50px;
    }


}