.carousel {
    height: 80vh;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.carousel .carousel_content {
    width: 1200px;
    height: 100%;
    overflow: hidden;
    padding: 0 2rem;

    background-color: rgba(128, 128, 128, 0.176);
}

.carousel .carousel_content img {
    width: 100%;
    height: 700px;
}


@media screen and (max-width: 2000) {}


@media screen and (max-width: 1024px) {
    .carousel {
        height: 70vh;
    }

    .carousel .carousel_content {
        width: 850px;
        padding: 0 .5rem;
        margin-top: 1rem;
    }

    .carousel .carousel_content img {
        width: 100%;
        height: 600px;
    }
}

@media screen and (max-width: 800px) {
    .carousel {
        height: 50vh;
    }

    .carousel .carousel_content {
        width: 600px;
        padding: 0 .5rem;
    }

    .carousel .carousel_content img {
        width: 100%;
        height: 350px;
    }
}

@media screen and (max-width: 576px) {
    .carousel {
        height: 50vh;
    }

    .carousel .carousel_content {
        width: 450px;
        padding: 0 1rem;
    }

    .carousel .carousel_content img {
        width: 100%;
        height: 350px;
    }
}