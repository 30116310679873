.features {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 2rem 2rem;



}


.feature_box {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;



    padding: 2rem;
}

.feature_box h3 {
    font-family: var(--font-family);
    font-weight: 200;
    text-transform: uppercase;

    font-size: 1.3rem;
}

.feature_box .fb_item {
    width: 200px;
    height: 200px;
    border-radius: 50%;

    margin-bottom: 1.5rem;

    overflow: hidden;


}

.feature_box .fb_item img {
    width: 100%;
    object-fit: cover;
}


.security img {
    margin-top: 2rem;
}

.quality img {
    margin-top: 1.3rem;
}

.cargo img {
    margin-top: 2rem;
}


@media screen and (max-width: 2000) {}


@media screen and (max-width: 1150px) {
    .features {
        padding: 2rem 4rem;

    }

    .feature_box {
        justify-content: space-between;
        padding: 0;
    }

    .features .feature_box .fb_item {
        width: 100px;
        height: 100px;
    }

    .feature_box h3 {
        font-size: 15px;
    }

    .service img {
        margin-top: 1.8rem;
    }


}

@media screen and (max-width: 750px) {
    .features {
        padding: 2rem 1rem;
        flex-wrap: wrap;
    }

    .feature_box {
        justify-content: space-between;
        padding: 0;
    }

    .features .feature_box .fb_item {
        width: 100px;
        height: 100px;
    }

    .feature_box h3 {
        font-size: 15px;
    }

    .service img {
        margin-top: 1.8rem;
    }


}

@media screen and (max-width: 450px) {



    .features {
        justify-content: space-around;
    }




}