.header {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4rem;

    position: relative;
}

header .header_sliderbox {
    width: 400px;
    height: 300px;

    overflow: hidden;
}