.footer {
    display: flex;
    align-items: center;
    justify-content: space-around;


    padding: 2rem 0;

    background-color: black;


    /* opacity: 0.2; */

}

.footer .footer_left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    width: 200px;
}

.footer .footer_left .contact,
.adress {
    font-family: var(--font-family);
    font-weight: 100;

    font-size: 15px;

    color: rgba(255, 255, 255, 0.685);
}

.footer .footer_logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .footer_logo img {
    width: 200px;
}


.footer .footer_right {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    color: rgba(255, 255, 255, 0.685);
}

.footer .footer_right .social {
    display: flex;
    align-items: center;
    justify-content: center;

}

.footer .footer_right .social li {
    font-size: 2rem;
    margin: 0 1.5rem;
}

.footer .footer_right .social li a {
    color: rgba(255, 255, 255, 0.685);
}



@media screen and (max-width: 2000) {}


@media screen and (max-width: 1150px) {
    .header {
        padding: 0;
    }


}

@media screen and (max-width: 750px) {

    .footer {

        flex-direction: column;
    }

    .footer .footer_left {

        align-items: center;
        text-align: center;
    }


    .footer .footer_left h4,
    .footer .footer_logo,
    .footer .footer_right {
        margin-top: 2rem;
    }


}