@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

a {
  text-decoration: none;
}



:root {
  --font-family: 'Poppins', sans-serif;


  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

  --color-bg: black;
  --color-footer: #031B34;
  --color-blog: #042c54;
  --color-text: #fefefe;
  --color-subtext: #FF8A71;
}

.mt {
  margin-top: 4rem;
}