.detail-main {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
}

.detail-top {

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1rem;


    margin-top: 2.5rem;
    text-align: center;
}

.detail-top .detail-main-image {
    display: flex;
    justify-content: center;

    width: 800px;
    height: 500px;

    margin-bottom: 1.5rem;

    cursor: pointer;
}

.detail-top .detail-main-image .main-image {

    width: 100%;
    object-fit: cover;
}

.detail-top .detail-info-top span:first-child,
.detail-top .detail-info-top+span {
    font-size: .8rem;
    font-weight: 200;
}

.detail-top .detail-info {
    text-align: start;
}

.detail-top .detail-info p {
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 2px;

}

.detail-top .detail-info .turnback {
    padding: 10px 20px;
}


/* *****************************DETAIL BOTTOM********* */

.detail-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 1rem 5rem;


}

.detail-bottom .detail-images-box {
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    box-shadow: 0 0 5px black;
    transition: scale 400ms ease-in;
    -webkit-transition: scale 400ms ease-in;
    -moz-transition: scale 400ms ease-in;
    -ms-transition: scale 400ms ease-in;
    -o-transition: scale 400ms ease-in;

    cursor: pointer;
}

.detail-bottom .detail-images-box .bottom-images {
    width: 100%;
    object-fit: cover;
}

.detail-bottom .detail-images-box:hover {
    scale: 1.2;
}

button {
    outline: none;
    border: none;
    padding: 20px 40px;
    background-color: black;
    color: white;
    letter-spacing: 2px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin: 2rem 0;
    transition: all 500ms ease-in-out;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
}

button:hover {
    background-color: white;
    color: black;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.444);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}


@media screen and (max-width: 2000) {}


@media screen and (max-width: 1150px) {
    .detail-top .detail-main-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 700px;
        height: 500px;

        margin-bottom: 1.5rem;
        margin-left: 2rem;
        cursor: pointer;
    }


    .detail-bottom {
        padding: 1rem .2rem;
    }
}

@media screen and (max-width: 800px) {
    .detail-top {
        flex-direction: column;
    }

    .detail-top .detail-main-image {
        display: flex;
        width: 600px;
        height: 400px;
        overflow: hidden;

        margin-bottom: 1rem;
    }

    .detail-top .detail-info {
        text-align: center;
    }

    .detail-top .detail-info p {

        font-size: 1.2rem;
    }

    .detail-bottom {
        padding: 1rem .8rem;
    }

    .detail-bottom .detail-images-box {
        width: 150px;
        height: 150px;

    }
}

@media screen and (max-width: 576px) {

    .detail-main .detail-top {
        margin-top: 4rem;
    }

    .detail-top .detail-main-image {
        display: flex;
        width: 300px;
        height: 200px;
        margin: auto;
        overflow: hidden;
    }

    .detail-top .detail-info {
        margin-top: 3rem;
        text-align: center;
    }

    .detail-top .detail-info p {

        font-size: 1.2rem;
    }

    .detail-bottom {
        padding: 1rem .8rem;
    }

    .detail-bottom .detail-images-box {
        width: 150px;
        height: 150px;

    }
}