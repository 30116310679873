.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 4rem;

    background-color: var(--color-bg);
}

.navigation .navigation_logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation .navigation_logo img {
    width: 150px;
}

.navigation .navigation_menu {

    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation .navigation_menu li {
    color: white;
    margin: .5rem;

    font-family: var(--font-family);
    font-weight: 200;
    text-transform: uppercase;

    transition: all 500ms ease-in-out;

}

.navigation .navigation_menu a {
    text-decoration: none;
}

.navigation .navigation_menu li:hover {
    border-bottom: .5px solid rgba(245, 222, 179, 0.2);
    transition: all 500ms ease-in-out;
}

.navigation .navigation_menu .prodBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation .navigation_menu .prodBox li {
    font-weight: 400;
}

.navigation .navigation_menu .line {
    width: .2px;
    height: 30px;
    background-color: rgba(245, 222, 179, 0.206);
    margin: .5rem;
}







.navigation .navigation_social {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation .navigation_social li {
    color: white;
    margin: .5rem;
    font-size: 1.5rem;

    cursor: pointer;
}

.navigation .navigation_social li a {
    color: white;
}

.navigation .navigation_burger {

    display: none;
    align-items: center;
    justify-content: center;

}

.navigation .navigation_burger li {
    font-size: 30px;
    color: white;

}

.navigation .navigation_burger .navigation_menu_smallscreen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}


.navigation .navigation_burger .navigation_menu_smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.944);
    transition: all 500ms ease-in-out;

    flex-direction: column;
    z-index: 5;
}

.navigation .navigation_burger .navigation_menu_smallscreen_overlay li {
    font-family: var(--font-family);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .4rem;
    margin: 1rem 0;

    cursor: pointer;

    transition: all 500ms ease-in-out;
}

.navigation .navigation_burger .navigation_menu_smallscreen_overlay li:hover {
    border-bottom: 1px solid wheat;
}

.navigation .navigation_burger .navigation_menu_smallscreen_overlay .overlay__close {
    font-size: 30px;
    color: wheat;
    cursor: pointer;

    position: absolute;
    top: 4rem;
    right: 2rem;

    z-index: 5;


}

@media screen and (max-width: 2000) {
    .navigation .navigation_burger .menu_line {
        display: none;
    }
}


@media screen and (max-width: 1150px) {
    .navigation {
        flex-direction: column;
    }

    .navigation .navigation_logo {
        margin-bottom: 1rem;
    }

    .navigation .navigation_menu li {
        font-size: .9rem;
        margin: .1rem;
        text-align: center;
    }

    .navigation .navigation_burger {
        display: none;
    }

    .navigation .navigation_social li {
        margin-top: 2rem;
    }


}

@media screen and (max-width: 750px) {
    .navigation {
        justify-content: center;
        position: relative;
    }

    .navigation .navigation_logo img {
        width: 150px;
    }

    .navigation .navigation_menu {
        display: none;
    }


    .navigation .navigation_social {
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
        left: 1rem;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }

    .navigation .navigation_burger {
        display: flex;

    }

    .navigation .navigation_burger .menu_line {
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }



    .navigation .navigation_burger .navigation_menu_smallscreen_overlay .navigation_menu_smallscreen li {
        font-size: 1.3rem;
        border-bottom: .5px solid rgba(211, 211, 211, 0.429);

    }

    .navigation .navigation_burger .navigation_menu_smallscreen_overlay .navigation_menu_smallscreen li:hover {
        scale: 1.3;

    }
}