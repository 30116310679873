.sets {
    padding: 2rem 4rem;
    background-color: rgba(224, 255, 255, 0.081);
}

.sets a {
    text-decoration: none;
    color: black;
}

.sets h3 {
    font-family: var(--font-family);
    text-transform: uppercase;
    border-bottom: 1px solid lightgray;
}

.sets_content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    margin-bottom: 4rem;

    padding: 1rem 0;

    overflow-x: scroll;

    gap: 20px;



}

.sets_content::-webkit-scrollbar {
    height: 5px;
}

.sets_content::-webkit-scrollbar-thumb {
    background-color: #88888837;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.sets_content::-webkit-scrollbar-thumb:hover {
    background-color: #55555561;
    /* Kaydırma çubuğu hover rengi */
}




.sets .sets_content .setBoxes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: var(--font-family);



    background-color: rgba(211, 211, 211, 0.475);


    width: 300px;
    height: 300px;

    border-radius: 20px;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.454);


    overflow: hidden;
    margin-bottom: 2rem;

}

.sets .line {

    border: 8px solid black;

}



.sets .sets_content .setBoxes h4 {
    font-size: 15px;
    font-weight: 200;

}

.sets .sets_content .setBoxes h3 {
    font-weight: 300;
    font-size: 18px;

}

.sets .sets_content .setBoxes .setBox_image {
    display: flex;
    width: 300px;
    height: 300px;
    margin-bottom: 1rem;

    overflow: hidden;

}

.sets .sets_content .setBoxes .setBox_image img {
    width: 100%;
    object-fit: cover;
}



@media screen and (max-width: 750px) {
    .sets {
        padding: 1rem;
    }

    .sets .sets_content {
        display: flex;
        align-items: center;
        justify-content: space-around;

        text-align: center;
    }



    .sets .sets_content .setBoxes {
        justify-content: flex-start;
        width: 180px;
        height: 200px;

    }

    .sets .sets_content .setBoxes .setBox_image {
        width: 100%;
        margin-bottom: 2rem;
    }

    .sets .sets_content .setBoxes h3 {
        font-size: .8rem;
        font-weight: 200;
    }




}