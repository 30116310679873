.products {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;


    padding: 2rem 0;

}


nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    margin-top: 2rem;



}

nav h3 {
    font-family: var(--font-family);

}

nav li {
    font-family: var(--font-family);
    border-bottom: 1px solid rgba(0, 0, 0, 0.205);

    color: black;

    transition: all 250ms ease-in;

    padding: 1.1rem;
    margin: 2rem;
    margin-bottom: 2rem;

    cursor: pointer;

}

nav li:hover {
    background-color: black;
    color: wheat;

    border-radius: 10px;

}

.products .products__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;

    margin: 1rem;
    padding: 1.2rem;

    width: 400px;
    height: 300px;
    overflow: hidden;

    border-radius: 15px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.305);

    transition: all 250ms ease-in-out;


}

.products .products__content:hover {

    scale: 1.1;

}

.products .products__content p {
    font-family: var(--font-family);
    color: black;
    margin-top: 1rem;
    margin-bottom: 0;
    font-weight: 300;
}

.products .products__content .model {
    font-weight: 200;
}


.products .products__content .products__content_imageBox {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    overflow: hidden;
}

.products .products__content img {
    width: 100%;
    object-fit: cover;
}




@media screen and (max-width: 2000) {}


@media screen and (max-width: 1150px) {
    nav {

        margin-top: 1rem;
        text-align: center;

        justify-content: space-evenly;
    }

    nav li {
        font-size: 15px;
        font-weight: 300;
        padding: .5rem;
        margin: .1rem;

    }

    .products .products__content {

        justify-content: space-around;

        margin: .5rem;
        padding: 1.2rem;

        width: 300px;
        height: 300px;
    }

    .products .products__content p {
        margin-top: 0;
    }
}

@media screen and (max-width: 750px) {
    .nav {
        justify-content: space-around;
    }

    .products .products__content {

        margin: 0 0 2rem 0;
        padding: 1.1rem;

        width: 350px;
        height: 300px;
    }

    nav {
        width: 100%;
        margin: auto;
        justify-content: space-between;

        margin-top: 2rem;
    }

    nav li {
        font-family: var(--font-family);
        border-bottom: 1px solid rgba(0, 0, 0, 0.205);

        padding: 0rem;
        margin: 0rem;
        margin-bottom: 0rem;

        width: 50px;
    }

    nav li:hover {
        background-color: transparent;
        color: black;
    }



}